<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Produtos</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="produto" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="row">
              <md-table-cell md-label="Categoria">{{
                row.item.categoria
              }}</md-table-cell>
              <md-table-cell md-label="Nome">{{
                row.item.titulo
              }}</md-table-cell>
              <md-table-cell md-label="Descrição">{{
                row.item.descricao
              }}</md-table-cell>
              <md-table-cell md-label="Preço">{{
                row.item.preco
              }}</md-table-cell>
              <md-table-cell md-label="Stock">{{
                row.item.stock
              }}</md-table-cell>
              <md-table-cell md-label="Comprados">{{
                row.item.comprado
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
  import api from "@/api/index";
  export default {
    data() {
      return {
        produto: [],
      };
    },
    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var produtos = await api.listarPordutos();
        produtos = Object.values(produtos);
        this.produto = produtos;
      },
    },
  };
</script>
<style></style>
