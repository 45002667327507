<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h4 class="title">
        XS Grid
        <small>Always Horizontal</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-xsmall-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-xsmall-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-xsmall-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-xsmall-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h4 class="title">
        SM Grid
        <small>Collapsed at 960px</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-small-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-small-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-small-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h4 class="title">
        MD Grid
        <small>Collapsed at 1280px</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-medium-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-medium-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-medium-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-medium-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-medium-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-medium-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h4 class="title">
        LG Grid
        <small>Collapsed at 1903px</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-large-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-large-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-large-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-large-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-large-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-large-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h4 class="title">
        XL Grid
        <small>larger than 1904px</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-xlarge-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-large-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-xlarge-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-xlarge-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-xlarge-size-100">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-xlarge-size-100</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h4 class="title pl-3">
        Mixed Grid
        <small>Showing different sizes on different screens</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-medium-size-33 md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-medium-size-33 md-small-size-50</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-medium-size-33 md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-medium-size-33 md-small-size-50</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-medium-size-33 md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-medium-size-33 md-small-size-50</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h4 class="title">
        Offset Grid
        <small>Adding some space when needed</small>
      </h4>
      <div class="md-layout">
        <div class="md-layout-item md-size-25 md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-size-25</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-size-25 ml-auto md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-size-25 ml-auto</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-25 mx-auto md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-size-25 mx-auto</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-size-25 mx-auto md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-size-25 mx-auto</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-50 mx-auto md-small-size-50">
          <md-card>
            <md-card-content>
              <div class="text-center">
                <code>md-size-50 mx-auto</code>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <h3 class="title text-center">Paragraphs</h3>
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h3>Some card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment. His many legs,
                pitifully thin compared with the size of the rest of him, waved
                about helplessly as he looked. "What's happened to me?" he
                thought.
              </p>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h3>Another card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment. His many legs,
                pitifully thin compared with the size of the rest of him, waved
                about helplessly as he looked. "What's happened to me?" he
                thought.
              </p>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <h3>Some card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment.
              </p>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <h3>Another card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment.
              </p>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <h3>Another card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment.
              </p>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <h3>Some card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment.
              </p>
            </div>
            <div class="md-layout-item md-size-66 md-small-size-100">
              <h3>Another card-title Here</h3>
              <p>
                One morning, when Gregor Samsa woke from troubled dreams, he
                found himself transformed in his bed into a horrible vermin. He
                lay on his armour-like back, and if he lifted his head a little
                he could see his brown belly, slightly domed and divided by
                arches into stiff sections. The bedding was hardly able to cover
                it and seemed ready to slide off any moment. One morning, when
                Gregor Samsa woke from troubled dreams, he found himself
                transformed in his bed into a horrible vermin. He lay on his
                armour-like back, and if he lifted his head a little he could
                see his brown belly, slightly domed and divided by arches into
                stiff sections. The bedding was hardly able to cover it and
                seemed ready to slide off any moment.
              </p>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.text-center {
  display: flex;
}
code {
  font-size: 87.5%;
  color: #e91e63;
  word-break: break-word;
}
</style>
