<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div v-if="listaEncomendas">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>local_shipping</md-icon>
            </div>
            <h4 class="title">Todas as suas Encomendas</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="encomenda" table-header-color="green">
              <md-table-row slot="md-table-row" slot-scope="row">
                <md-table-cell md-label="ID ">{{ row.item.id }}</md-table-cell>
                <md-table-cell md-label="Utilizador ID">{{
                  row.item.user_id
                }}</md-table-cell>
                <md-table-cell md-label="Estado"
                  >{{ encomendaEstado }}
                </md-table-cell>

                <md-table-cell md-label="Pordutos">
                  <button
                    class="verMais"
                    @click="verDetalhes(row.item.dt_created)"
                  >
                    Detalhes
                  </button>
                </md-table-cell>
                <md-table-cell md-label="Preço">{{
                  row.item.total
                }}</md-table-cell>
                <md-table-cell md-label="Data">{{
                  row.item.dt_created
                }}</md-table-cell>
                <md-table-cell md-label="Encomenda" v-if="souAdmin">
                  <button
                    class="verMais"
                    @click="entregarEncomenda(row.item.id)"
                  >
                    Entregar
                  </button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <div v-if="detalhes">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>done</md-icon>
            </div>
            <h4 class="title">Encomenda</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="osProdutos" table-header-color="green">
              <md-table-row slot="md-table-row" slot-scope="row">
                <md-table-cell md-label="ID da Encomenda">{{
                  row.item.id
                }}</md-table-cell>
                <md-table-cell md-label="Nome dos Produtos">{{
                  row.item.nomeprod
                }}</md-table-cell>
                <md-table-cell md-label="Quantidade">{{
                  row.item.quantidade
                }}</md-table-cell>
                <md-table-cell md-label="Preço">{{
                  row.item.preco + " $"
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-button class="md-danger" @click="voltarAtras()"
            >Voltar Atrás</md-button
          >
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
  import api from "@/api/index";
  export default {
    data() {
      return {
        encomendaEstado: [],
        detalhes: false,
        encomenda: [],
        osProdutos: [],
        todosProdutos: [],
        encontrarEncomenda: [],
        listaEncomendas: true,
        souAdmin: false,
        tempp: [],
      };
    },
    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var utl = localStorage.getItem("clienteLogado");
        utl = JSON.parse(utl);
        var todosUtl = await api.listarUtilizadores();
        todosUtl = Object.values(todosUtl);
        var oUtilizador = todosUtl.find((x) => x.user_id == utl[0].user_id);

        var id = {
          user_id: utl[0].user_id,
        };
        if (oUtilizador.admin == 1) {
          this.souAdmin = true;
          var encomendas = await api.todasEncomendas(id);
          encomendas = Object.values(encomendas);
          this.encomenda = encomendas;
        } else {
          var encomendas = await api.listarEncomendas(id);
          encomendas = Object.values(encomendas);
          this.encomenda = encomendas;
        }
        for (let i = 0; i < this.encomenda.length; i++) {
          if (this.encomenda[i].estado == "0") {
            this.encomendaEstado = "Em preparação !!";
          } else {
            this.encomendaEstado = "Entregue !!";
          }
        }
      },

      verDetalhes(data) {
        this.todosProdutos = [];

        this.encontrarEncomenda = this.encomenda.find(
          (x) => x.dt_created == data
        );
        var produtosEnco = JSON.parse(this.encontrarEncomenda.produtos);
        var oId = JSON.parse(this.encontrarEncomenda.id);
        for (let x = 0; x < produtosEnco.length; x++) {
          this.todosProdutos.push({
            id: oId,
            nomeprod: produtosEnco[x].nomeprod,
            quantidade: produtosEnco[x].quantidade,
            preco: produtosEnco[x].preco,
          });
        }
        this.osProdutos = this.todosProdutos;
        this.listaEncomendas = false;
        this.detalhes = true;
      },
      entregarEncomenda(id) {
        var encomnedaEscolhida = this.encomenda.find((x) => x.id == id);
        console.log(encomnedaEscolhida);
        if (encomnedaEscolhida.estado == "1") {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "ُEssa encomenda já foi entregue !! "
          );
        } else {
          encomnedaEscolhida.estado = "1";
        }
        console.log(encomnedaEscolhida);
        this.inicio();
      },
      voltarAtras() {
        this.listaEncomendas = true;
        this.detalhes = false;
      },
      notifyVue(verticalAlign, horizontalAlign, cor, texto) {
        this.$notify({
          message: texto,
          icon: "add_alert",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: cor,
          timeout: 2500,
        });
      },
    },
  };
</script>
<style>
  .tabela {
    text-align: center !important;
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    margin-top: 0px;
    text-align: right !important;
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px;
  }
  .verMais {
    border-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #4ea852;
    color: white;
  }
</style>
