<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ nomeUtl }}
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ nomeUtl }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a href="#/pages/user">
                  <md-icon>account_circle</md-icon>
                  <span class="sidebar-normal">Perfil</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from "vue2-transitions";

  import api from "@/api/index";
  export default {
    components: {
      CollapseTransition,
    },
    props: {
      avatar: {
        type: String,
        default: "./img/sporting.jpg",
      },
    },
    data() {
      return {
        nomeUtl: "",
        isClosed: true,
      };
    },
    methods: {
      clicked: function (e) {
        e.preventDefault();
      },

      toggleMenu: function () {
        this.isClosed = !this.isClosed;
      },

      async inicio() {
        var utlLogado = localStorage.getItem("clienteLogado");
        utlLogado = JSON.parse(utlLogado);
        this.user_id = utlLogado[0].user_id;

        var utl = await api.listarUtilizadores();
        utl = Object.values(utl);
        var utilizador = utl.find((x) => x.user_id == this.user_id);

        this.nomeUtl = utilizador.nome + " " + utilizador.apelido;
      },
    },
    mounted() {
      this.inicio();
      this.$root.$on("perfilAlterado", () => {
        this.inicio();
      });
    },
  };
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
