<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <signup-card>
        <h2 class="title text-center" slot="title">Registo</h2>
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto"
          slot="content-left"
        >
          <md-field class="md-form-group" slot="inputs">
            <md-icon>face</md-icon>
            <label>Primeiro Nome</label>
            <md-input v-model="firstname"></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>face </md-icon>
            <label>Ultimo Nome</label>
            <md-input v-model="lastname"></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>email</md-icon>
            <label>Email</label>
            <md-input v-model="email" type="email"></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Password</label>
            <md-input v-model="password"></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto"
          slot="content-right"
        >
          <md-field class="md-form-group" slot="inputs">
            <md-icon class="material-icons">room</md-icon>
            <label>Localidade</label>
            <md-input v-model="localidade"></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon class="material-icons">room</md-icon>
            <label>Pais</label>
            <md-input v-model="pais"></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon class="material-icons">room</md-icon>
            <label>Codigo Postal</label>
            <md-input v-model="codPostal"></md-input>
          </md-field>
          <div class="danger" v-if="erro">
            Um ou mais campos nao estao preenchidos
          </div>
          <div class="success" v-if="registo">Registo feito com sucesso !</div>
          <div class="danger" v-if="regsitoFalhado">
            Ocorreu um erro no registo !
          </div>
          <div class="danger" v-if="emailEmUso">
            Esse email já está em uso !
          </div>

          <div class="button-container">
            <md-button
              href
              class="md-success md-round mt-4"
              slot="footer"
              @click="registarUtilizador()"
              >Registar</md-button
            >
          </div>
        </div>
      </signup-card>
    </div>
  </div>
</template>
<script>
  import { SignupCard } from "@/components";
  import api from "@/api/index";
  export default {
    components: {
      SignupCard,
    },
    data() {
      return {
        firstname: "",
        lastname: "",
        boolean: false,
        email: "",
        password: "",
        localidade: "",
        pais: "",
        codPostal: "",
        erro: false,
        registo: false,
        regsitoFalhado: false,
        emailEmUso: false,
        utilizadores: [],
      };
    },
    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var utl = await api.listarUtilizadores();
        utl = Object.values(utl);
        this.utilizadores = utl;
      },
      async registarUtilizador() {
        if (
          this.firstname.trim() == "" ||
          this.lastname.trim() == "" ||
          this.email.trim() == "" ||
          this.password.trim() == "" ||
          this.localidade.trim() == "" ||
          this.pais.trim() == "" ||
          this.codPostal.trim() == ""
        ) {
          this.erro = true;
          setTimeout(() => {
            this.erro = false;
          }, 2000);
          return;
        } else {
          var encontrarEmail = this.utilizadores.find(
            (x) => x.email == this.email
          );
          if (encontrarEmail) {
            this.emailEmUso = true;
            setTimeout(() => {
              this.emailEmUso = false;
            }, 2000);
            return;
          } else {
            let dadosInseridos = {
              user_id: this.gerarUserId(),
              nome: this.firstname,
              apelido: this.lastname,
              email: this.email,
              password: this.password,
              localidade: this.localidade,
              pais: this.pais,
              cod_postal: this.codPostal,
            };
            var res = await api.adicionarUtilizador(dadosInseridos);

            if (res.resultado == "OK") {
              this.registo = true;
              setTimeout(() => {
                this.regsito = false;
              }, 2000);
              setTimeout(() => {
                this.$router.push("/login");
              }, 2500);
              return;
            } else {
              this.regsitoFalhado = true;
              setTimeout(() => {
                this.regsitoFalhado = false;
              }, 2000);
            }
          }
        }
      },
      gerarUserId() {
        return (
          Math.random().toString(36).substring(2, 7).toUpperCase() +
          "-" +
          Math.random().toString(36).substring(2, 7).toUpperCase() +
          "-" +
          Math.random().toString(36).substring(2, 7).toUpperCase() +
          "-" +
          Math.random().toString(36).substring(2, 7).toUpperCase()
        );
      },
    },
  };
</script>
<style></style>
