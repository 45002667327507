<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <img src="/img/yourcode.jpg" />
    </div>

    <md-card-content>
      <h6 class="category text-gray">Your Code</h6>
      <h4 class="card-title">Who we R?</h4>
      <p class="card-description textoDesc">
        We are a web development company inserted in the REPUBLICA 45 group
        (fyi), so we trust our design to "A Creatura", our videos to "Emotion
        Films", our marketing to "BYD - Boost Your Digital" and our strategy to
        "Convert". All of this to deliver the best to you!
        <br />
        <br />
        We have more to offer you. Check our group website and find out more!
        Don't get us wrong, we are good but we also trust our partners.
      </p>
      <md-button
        href="https://yourcode.pt/"
        class="md-round"
        :class="getColorButton(buttonColor)"
        >Visit</md-button
      >
    </md-card-content>
  </md-card>
</template>
<script>
  export default {
    name: "user-card",
    props: {
      cardUserImage: {
        type: String,
        default: "./img/faces/marc.jpg",
      },
      buttonColor: {
        type: String,
        default: "",
      },
    },
    data() {
      return {};
    },
    methods: {
      getColorButton: function (buttonColor) {
        return "md-" + buttonColor + "";
      },
    },
  };
</script>
<style>
  .textoDesc {
    text-align: justify;
  }
</style>
