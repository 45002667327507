<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>exposure_plus_1</md-icon>
          </div>
          <h4 class="title">Adicionar um Produto</h4>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label>Categoria:</label>
            <md-select v-model="categoria">
              <md-option value="sapatilha">Sapatilha</md-option>
              <md-option value="casacos">Casaco</md-option>
              <md-option value="calças">Calças</md-option>
              <md-option value="tshirt">T-Shirt</md-option>
              <md-option value="sweatshirt">Sweatshirt</md-option>
            </md-select>
          </md-field>

          <md-field>
            <label>Titulo:</label>
            <md-input v-model="titulo"></md-input>
          </md-field>
          <md-field>
            <label>Descrição:</label>
            <md-input v-model="descricao"></md-input>
          </md-field>
          <md-field>
            <label>Preço:</label>
            <md-input v-model="preco"></md-input>
          </md-field>
          <md-field>
            <label>Stock:</label>
            <md-input v-model="stock"></md-input>
          </md-field>
          <md-field>
            <label>Imagem:</label>
            <md-input v-model="imagem"></md-input>
          </md-field>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-success" @click="adicionarProduto()"
            >Submit</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
  import api from "@/api/index";
  export default {
    components: {},
    data() {
      return {
        titulo: "",
        descricao: "",
        preco: "",
        stock: "",
        categoria: "",
        imagem: "",
      };
    },

    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var produtos = await api.listarPordutos();
        produtos = Object.values(produtos);
        this.produto = produtos;
      },
      async adicionarProduto() {
        if (
          this.categoria.trim() == "" ||
          this.titulo.trim() == "" ||
          this.descricao.trim() == "" ||
          this.preco.trim() == "" ||
          this.stock.trim() == "" ||
          this.imagem.trim() == ""
        ) {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "Preencha todos os campos !! "
          );
        } else if (isNaN(this.preco) == true || isNaN(this.stock) == true) {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "Os campos Preço e Stock só aceitam numeros !! "
          );
        } else {
          var produtoDescricao = this.produto.find(
            (x) => x.descricao == this.descricao
          );

          if (produtoDescricao) {
            this.notifyVue(
              "top",
              "right",
              "danger",
              "Esse produto já existe !! "
            );
          } else {
            var novoProduto = {
              categoria: this.categoria,
              titulo: this.titulo,
              descricao: this.descricao,
              preco: this.preco,
              stock: this.stock,
              imagem: this.imagem,
            };
            var res = await api.adicionarProduto(novoProduto);
            if (res.resultado == "OK") {
              this.notifyVue(
                "top",
                "right",
                "success",
                "Produto registado com sucessso !! "
              );
              return;
            } else {
              this.notifyVue(
                "top",
                "right",
                "danger",
                "Ocorreu um erro no registo do produto !! "
              );
            }
          }
        }
      },
      notifyVue(verticalAlign, horizontalAlign, cor, texto) {
        this.$notify({
          message: texto,
          icon: "add_alert",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: cor,
          timeout: 2500,
        });
      },
    },
  };
</script>
<style></style>
