<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <div class="success" v-if="loginSucesso">Login feito com sucesso!</div>
      <div class="info" v-if="erroFaltaDados">Por favor insira os dados !</div>
      <div class="info" v-if="erroDadosErrados">
        Os dados estao incorretos !
      </div>
      <login-card>
        <h3 slot="title" class="title" style="color: black; margin-top: 50px">
          Login
        </h3>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password</label>
          <md-input v-model="password"></md-input>
        </md-field>
        <md-field> </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg"
          @click="loginUtilizador()"
        >
          Entrar
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
  import api from "@/api/index";
  import { LoginCard } from "@/components";
  export default {
    components: {
      LoginCard,
    },
    data() {
      return {
        email: "",
        password: "",
        erroFaltaDados: false,
        erroDadosErrados: false,
        loginSucesso: false,
      };
    },
    methods: {
      inicio() {
        localStorage.removeItem("clienteLogado");
      },
      async loginUtilizador() {
        if (this.email.trim() == "" || this.password.trim() == "") {
          this.erroFaltaDados = true;
          setTimeout(() => {
            this.erroFaltaDados = false;
          }, 2000);
          return;
        } else {
          var json = {
            email: this.email,
            password: this.password,
          };
          var login = await api.login(json);
          login = Object.values(login);
          if (login.length == 0) {
            this.erroDadosErrados = true;
            setTimeout(() => {
              this.erroDadosErrados = false;
            }, 2000);
            return;
          } else {
            localStorage.setItem("clienteLogado", JSON.stringify(login));
            this.loginSucesso = true;
            setTimeout(() => {
              this.$router.push("/dashboard");
            }, 2500);
            return;
          }
        }
      },
    },
    mounted() {
      this.inicio();
    },
  };

  // email , password se estiver certo objeto , se nao objeto vazio
</script>

<style>
  .info {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }
</style>
