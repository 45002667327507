<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu temp" :class="{ show: isOpen }">
        <div class="tabela">
          <li>
            <a>Produtos</a>
          </li>
          <li>
            <a>Precos</a>
          </li>
          <li>
            <a>Quantidades</a>
          </li>
          <li>
            <a>Imagens</a>
          </li>
          w
          <li>
            <a>Eliminar</a>
          </li>
        </div>
        <div class="tabela2" v-for="(produto, i) in carrinhoCliente" :key="i">
          <li class="dadosTabela" href="#">
            {{ produto.titulo }}
          </li>
          <li class="dadosTabela" href="#">
            {{ produto.preco + " $" }}
          </li>
          <li class="dadosTabela">
            <button
              class="btsQuantidades material-icons"
              @click="tirarQuantidade(produto)"
            >
              keyboard_arrow_down</button
            >{{ obterQuantidade(produto) }}

            <button
              class="btsQuantidades material-icons"
              @click="adicionarQuantidade(produto)"
            >
              expand_less
            </button>
          </li>

          <img class="imagensCarro" :src="produto.imagem" alt="" />

          <li
            @click="removerProduto(produto)"
            class="dadosTabela"
            href="#"
            style="text-align: center"
          >
            <md-icon>clear</md-icon>
          </li>
        </div>
        <li>
          <button
            class="btCompra md-button md-success md-round md-theme-default"
            @click="calcularTotalGasto()"
          >
            Comprar
          </button>
        </li>

        <li></li>
        <li></li>
        <li class="valorTotal">Valor total:</li>
        <li class="valorTotal">{{ valorTotal }} $</li>

        <!-- <li class="header-title">Images</li>
        <li
          v-for="item in sidebarImages"
          :key="item.image"
          :class="{ active: item.active }"
          @click="changeSidebarImage(item)"
        >
          <a class="img-holder switch-trigger">
            <img :src="item.image" alt="" />
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
  import api from "@/api/index";
  export default {
    props: {
      sidebarMini: Boolean,
      sidebarImg: Boolean,
    },
    data() {
      return {
        temp: [],
        valorTotal: 0,
        totalQuantidades: 0,
        totalPreco: 0,
        numeroProdCarrinho: 0,
        carrinhoCliente: [],
        utilizadores: [],
        isOpen: false,
        backgroundImage: "./img/sidebar-2.jpg",
      };
    },
    methods: {
      tirarQuantidade(produto) {
        var prodCarro = this.carrinhoCliente.find((x) => x.id == produto.id);
        var encontrarProd = this.carrinhoCliente.indexOf(prodCarro);
        if (this.carrinhoCliente[encontrarProd].quantidade > 1) {
          this.carrinhoCliente[encontrarProd].quantidade =
            this.carrinhoCliente[encontrarProd].quantidade - 1;
          this.somarPrecos();
          this.$forceUpdate();
          return;
        } else {
          this.carrinhoCliente.splice(encontrarProd, 1);
          this.somarPrecos();
          this.$forceUpdate();
          return;
        }
      },
      adicionarQuantidade(produto) {
        var prodCarro = this.carrinhoCliente.find((x) => x.id == produto.id);
        var encontrarProd = this.carrinhoCliente.indexOf(prodCarro);
        this.carrinhoCliente[encontrarProd].quantidade =
          this.carrinhoCliente[encontrarProd].quantidade + 1;

        this.somarPrecos();

        this.$forceUpdate();
      },
      obterQuantidade(prod) {
        if (prod) {
          return this.carrinhoCliente.find((x) => x.id == prod.id).quantidade;
        }
      },
      removerProduto(prod) {
        if (confirm("Tem a certeza?") == true) {
          var qualProduto = this.carrinhoCliente.find((x) => x.id == prod.id);
          var encontrarProduto = this.carrinhoCliente.indexOf(qualProduto);
          this.carrinhoCliente.splice(encontrarProduto, 1);
          this.somarPrecos();
        }
        this.numeroProdCarrinho = this.carrinhoCliente.length;

        this.$forceUpdate();
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      async inicio() {
        var utilizador = await api.listarUtilizadores();
        utilizador = Object.values(utilizador);
        this.utilizadores = utilizador;
        var produtos = await api.listarPordutos();
        produtos = Object.values(produtos);
        this.produto = produtos;
      },
      toggleDropDown() {
        this.isOpen = !this.isOpen;
      },
      closeDropDown() {
        this.isOpen = false;
      },
      toggleList(list, itemToActivate) {
        list.forEach((listItem) => {
          listItem.active = false;
        });
        itemToActivate.active = true;
      },
      notifyVue(verticalAlign, horizontalAlign, cor, texto) {
        this.$notify({
          message: texto,
          icon: "error",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: cor,
          timeout: 2000,
        });
      },
      async somarPrecos() {
        if (this.carrinhoCliente.length == 0) {
          this.valorTotal = 0;
          this.$forceUpdate();
        } else {
          this.valorTotal = 0;
          for (let i = 0; i < this.carrinhoCliente.length; i++) {
            this.valorTotal +=
              this.carrinhoCliente[i].quantidade *
              this.carrinhoCliente[i].preco;
          }
        }
      },
      async calcularTotalGasto() {
        if (this.carrinhoCliente.length == 0) {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "ERRO - Adicione primeiro um item ao carrinho !!! "
          );
        } else {
          let carro = [];
          var quantidadesSomadas = 0;
          for (let i = 0; i < this.carrinhoCliente.length; i++) {
            var oUtl = localStorage.getItem("clienteLogado");
            oUtl = JSON.parse(oUtl);
            var dadosUtl = this.utilizadores.find(
              (x) => x.user_id == oUtl[0].user_id
            );
            quantidadesSomadas += this.carrinhoCliente[i].quantidade;
            carro.push({
              id: this.carrinhoCliente[i].id,
              nomeprod: this.carrinhoCliente[i].titulo,
              quantidade: this.carrinhoCliente[i].quantidade,
              preco: this.carrinhoCliente[i].preco,
            });
          }
          var carrinhoString = JSON.stringify(carro);

          let carroFinal = {
            user_id: dadosUtl.user_id,
            produtos: carrinhoString,
            quantidade: quantidadesSomadas,
            total: this.valorTotal,
          };
          var temp = await api.adicionarEncomenda(carroFinal);
          this.valorTotal = 0;
          this.carrinhoCliente = [];
        }
      },
    },
    mounted() {
      this.inicio();
      this.$root.$on("passarProduto", (prod) => {
        if (prod.stock == 0) {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "ERRO - O produto que escolheu esta sem stock !!! "
          );
          prod.titulo = "PRODUTO OUT OF STOCK";
          prod.descricao = "";
        } else {
          if (this.carrinhoCliente.length == 0) {
            this.carrinhoCliente.push({
              id: prod.id,
              titulo: prod.titulo,
              descricao: prod.descricao,
              quantidade: 1,
              preco: prod.preco,
              imagem: prod.imagem,
            });
            // this.temp.push({
            //   nome: prod.titulo,
            //   quantidade: 1,
            //   preco: prod.preco,
            //   descricao: prod.descricao,
            // });
          } else {
            var produtosNoCarro = this.carrinhoCliente.find(
              (x) => x.id == prod.id
            );
            if (!produtosNoCarro) {
              this.carrinhoCliente.push({
                id: prod.id,
                titulo: prod.titulo,
                descricao: prod.descricao,
                quantidade: 1,
                preco: prod.preco,
                imagem: prod.imagem,
              });
              // this.temp.push({
              //   id: prod.id,
              //   nome: prod.titulo,
              //   quantidade: 1,
              //   preco: prod.preco,
              //   descricao: prod.descricao,
              // });
            } else {
              var produtoExistente = this.carrinhoCliente.find(
                (x) => x.id == prod.id
              );
              // var prodEncontrar = this.temp.find((x) => x.id == prod.id);
              var indexDoProduto =
                this.carrinhoCliente.indexOf(produtoExistente);

              // var indexProd = this.temp.indexOf(prodEncontrar);
              this.carrinhoCliente[indexDoProduto].quantidade =
                this.carrinhoCliente[indexDoProduto].quantidade + 1;

              // this.temp[indexProd].quantidade =
              //   this.temp[indexProd].quantidade + 1;
            }
          }
        }

        this.numeroProdCarrinho = this.carrinhoCliente.length;
        this.somarPrecos();

        this.$forceUpdate();
        return;
      });
    },
  };
</script>
<style>
  .imagensCarro {
    height: 90px !important;
    width: 90px !important;
    margin-bottom: 10px !important;
  }
  .temp {
    width: 600px !important;
  }
  .btsQuantidades {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px;
  }
  .tabela {
    text-align: center !important;
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    margin-top: 0px;
    text-align: right !important;
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px;
  }
  .tabela2 {
    margin-top: 10px !important;
    text-align: center;
    margin-right: 10px;
    border-radius: 0;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    margin-top: 0px;
    text-align: center !important;
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px;
  }
  .centered-row {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .button-container .btn {
    margin-right: 10px;
  }

  .centered-buttons {
    display: flex;
    justify-content: center;
  }
  .dadosTabela {
    width: 120px !important;
    height: 90px !important;
    text-align: center !important;
    display: table-cell Im !important;
    vertical-align: middle !important;
  }
  .btCompra {
    width: 400px;
    height: 30px;
  }
  .valorTotal {
    margin-top: 5px;
    width: 70px !important;
  }
</style>
