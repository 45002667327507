<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <md-list-item href="#/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>

            <md-list-item href="#/pages/user">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
            <md-list-item href="#/login">
              <i class="material-icons">logout</i>
              <p class="hidden-lg hidden-md">Log Out</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
  export default {
    name: "stats-card",
    data() {
      return {
        numeroProdCarrinho: 0,
        carrinhoCliente: [],
      };
    },
    methods: {
      //   tirarQuantidade() {
      //   },
      //   adicionarQuantidade() {
      //   },
      //   obterQuantidade(prod) {
      //     if (prod) {
      //       return this.carrinhoCliente.find((x) => x.id == prod.id).quantidade;
      //     }
      //   },
      //   removerProduto(prod) {
      //     // for (let i = 0; i < this.carrinhoCliente.length; i++) {
      //     if (confirm("Tem a certeza?") == true) {
      //       var qualProduto = this.carrinhoCliente.find((x) => x.id == prod.id);
      //       var encontrarProduto = this.carrinhoCliente.indexOf(qualProduto);
      //       this.carrinhoCliente.splice(encontrarProduto, 1);
      //       //  }
      //     }
      //     this.numeroProdCarrinho = this.carrinhoCliente.length;
      //   },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      async inicio() {
        var produtos = await api.listarPordutos();
        produtos = Object.values(produtos);
        this.produto = produtos;
      },
      // },
      // mounted() {
      //   this.inicio();
      //   this.$root.$on("passarProduto", (prod) => {
      //     if (this.carrinhoCliente.length == 0) {
      //       prod.quantidade = 1;
      //       this.carrinhoCliente.push(prod);
      //     } else {
      //       var produtosNoCarro = this.carrinhoCliente.find(
      //         (x) => x.id == prod.id
      //       );
      //       if (!produtosNoCarro) {
      //         prod.quantidade = 1;
      //         this.carrinhoCliente.push(prod);
      //       } else {
      //         var produtoExistente = this.carrinhoCliente.find(
      //           (x) => x.id == prod.id
      //         );
      //         var indexDoProduto = this.carrinhoCliente.indexOf(produtoExistente);
      //         this.carrinhoCliente[indexDoProduto].quantidade =
      //           this.carrinhoCliente[indexDoProduto].quantidade + 1;
      //         this.$forceUpdate();
      //       }
      //     }
      //     this.numeroProdCarrinho = this.carrinhoCliente.length;
      //   });
    },
  };
</script>
<style scoped>
  .tabela {
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    margin-top: 0px;
    text-align: right !important;
    display: grid;
    grid-template-columns: 10fr 10fr 10fr 10fr;
  }
  .tabela2 {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 0;
    width: 100%;
    height: 70px;
    margin: 0 auto;
    margin-top: 0px;
    text-align: center !important;
    display: grid;
    grid-template-columns: 90px 120px 125px 125px;
  }

  .imagens {
    border-radius: 0;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    margin-top: 0px;
  }
  .btsQuantidades {
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
  }
  li {
    width: 100px !important;
  }
</style>
