<template>
  <form>
    <md-card>
      <md-card-header
        class="md-card-header-icon"
        :class="getClass(headerColor)"
      >
        <div class="card-icon">
          <md-icon>person</md-icon>
        </div>
        <h4 class="title">Bem-vindo ao seu Perfil</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Your Code</label>
              <md-input v-model="disabled" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50"></div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Primeiro Nome</label>
              <md-input v-model="firstname"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Ultimo Nome</label>
              <md-input v-model="lastname"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Localidade</label>
              <md-input v-model="localidade" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Pais</label>
              <md-input v-model="pais" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Codigo Postal</label>
              <md-input v-model="codPostal"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Nova Password</label>
              <md-input v-model="novaPass" type="password"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Confirmar Password</label>
              <md-input v-model="novaPass2" type="password"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 text-right">
            <md-button
              class="md-raised md-success mt-4"
              @click="confirmarPassword()"
              >Salvar</md-button
            >
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import api from "@/api/index";
  export default {
    name: "edit-profile-form",
    props: {
      headerColor: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        users: [],
        disabled: null,
        lastname: "",
        firstname: "",
        localidade: "",
        pais: "",
        codPostal: "",
        novaPass: "",
        novaPass2: "",
        user_id: "",
      };
    },
    methods: {
      getClass: function (headerColor) {
        return "md-card-header-" + headerColor + "";
      },
      async inicio() {
        var utlLogado = localStorage.getItem("clienteLogado");
        utlLogado = JSON.parse(utlLogado);
        this.user_id = utlLogado[0].user_id;

        var utl = await api.listarUtilizadores();
        utl = Object.values(utl);
        var utilizador = utl.find((x) => x.user_id == this.user_id);
        this.users = utl;
        this.firstname = utilizador.nome;
        this.lastname = utilizador.apelido;
        this.localidade = utilizador.localidade;
        this.pais = utilizador.pais;
        this.codPostal = utilizador.cod_postal;
      },

      async confirmarPassword() {
        let dadosAtualizados = {
          user_id: this.user_id,
          nome: this.firstname,
          apelido: this.lastname,
          localidade: this.localidade,
          pais: this.pais,
          cod_postal: this.codPostal,
          password: this.novaPass,
        };
        if (this.novaPass != this.novaPass2) {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "As novas passwords nao estao iguais  !!! "
          );
        } else {
          if (this.novaPass.trim() == "" && this.novaPass2.trim() == "") {
            var res = await api.updateUtilizadores(dadosAtualizados);
            if (res.resultado == "OK") {
              this.inicio();
              this.notifyVue("top", "right", "success", "Perfil Editado ! ");
              this.$root.$emit("perfilAlterado", dadosAtualizados);
            } else {
              this.notifyVue(
                "top",
                "right",
                "danger",
                "Erro ao editar o perfil  !!! "
              );
            }
          } else {
            let res1 = await api.updateUtilizadoresPass(dadosAtualizados);
            if (res1.resultado == "OK") {
              this.inicio();
              this.notifyVue("top", "right", "success", "Perfil Editado ! ");
            } else {
              this.notifyVue(
                "top",
                "right",
                "danger",
                "Erro ao editar o perfil  !!! "
              );
              this.$root.$emit("perfilAlterado", dadosAtualizados);
            }
          }
        }
      },
      notifyVue(verticalAlign, horizontalAlign, cor, texto) {
        this.$notify({
          message: texto,
          icon: "priority_high",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: cor,
          timeout: 2500,
        });
      },
    },
    mounted() {
      this.inicio();
    },
  };
</script>
<style></style>
