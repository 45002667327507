<template>
  <div id="temp">
    <div class="md-layout">
      <div
        v-for="(prod, i) in produto"
        :key="i"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <product-card class="carrinhoCli" header-animation="true">
          <img class="img" slot="imageHeader" :src="prod.imagem" />

          <h4 slot="title" class="title">
            <a href="#">{{ prod.titulo }}</a>
          </h4>
          <div slot="description" class="card-description">
            {{ prod.descricao }}.
          </div>
          <template slot="footer">
            <div class="price">
              <h4>{{ prod.preco }}$</h4>
            </div>
            <div class="stats">
              <p class="category hoverRed pointer" @click="comprarProd(prod)">
                <md-icon class="hoverRed">shopping_cart</md-icon>
                Comprar
              </p>
            </div>
          </template>
        </product-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { ProductCard } from "@/components";
  import api from "@/api/index";

  export default {
    name: "temp",
    components: {
      ProductCard,
    },

    data() {
      return {
        produto: [],
        produtosComprar: [],
      };
    },
    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var produtos = await api.listarPordutos();
        produtos = Object.values(produtos);
        this.produto = produtos;
      },
      comprarProd(prod) {
        this.$root.$emit("passarProduto", prod);
      },
    },
  };
</script>
<style scoped>
  .produtoDes {
    font-size: 14px;
    text-align: center;
  }

  .hoverRed:hover {
    color: red !important;
  }
</style>
