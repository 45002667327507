<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu> </user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="admin"
          :link="{ name: 'Produtos', icon: 'inventory', path: '/login' }"
        >
          <sidebar-item
            :link="{
              name: 'Adicionar Pordutos',
              icon: 'add',
              path: '/forms/regular',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Estatísticas',
              icon: 'show_chart',
              path: '/table-list/regular',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Editar Produtos',
              icon: 'mode_edit',
              path: '/editarprods',
            }"
          >
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Encomendas',
            icon: 'local_shipping',
            path: '/encomendas',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="admin"
          :link="{
            name: 'Gerir Utilizadores',
            icon: 'admin_panel_settings',
            path: '/editarutl',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Log Out', icon: 'logout', path: '/login' }"
        ></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar> </top-navbar>
      <fixed-plugin>
        ref="qwerty" :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor" :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg" :image.sync="sidebarBackgroundImage"
      </fixed-plugin>
      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  import PerfectScrollbar from "perfect-scrollbar";
  import "perfect-scrollbar/css/perfect-scrollbar.css";

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
      document.getElementsByClassName(className)[0].scrollTop = 0;
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  function reinitScrollbar() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  }

  import TopNavbar from "./TopNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import MobileMenu from "./Extra/MobileMenu.vue";
  import FixedPlugin from "../../FixedPlugin.vue";
  import UserMenu from "./Extra/UserMenu.vue";
  import { ZoomCenterTransition } from "vue2-transitions";

  import api from "@/api/index";

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      FixedPlugin,
      MobileMenu,
      UserMenu,
      ZoomCenterTransition,
    },
    data() {
      return {
        sidebarBackgroundColor: "black",
        sidebarBackground: "green",
        sidebarBackgroundImage: "./img/sidebar-2.jpg",
        sidebarMini: true,
        sidebarImg: true,
        utilizadores: [],
        admin: false,
      };
    },
    mounted() {
      this.verficarAdmin();
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      },
      async verficarAdmin() {
        console.log("ola");
        var utilizador = localStorage.getItem("clienteLogado");
        utilizador = JSON.parse(utilizador);
        console.log(utilizador);

        if (utilizador[0].admin == 1) {
          this.admin = true;
        } else {
          this.admin = false;
        }
      },
    },
    updated() {
      reinitScrollbar();
    },
    mounted() {
      reinitScrollbar();
      this.verficarAdmin();
    },
    watch: {
      sidebarMini() {
        this.minimizeSidebar();
      },
    },
  };
</script>
<style lang="scss">
  $scaleSize: 0.95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }
  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }
  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }
  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
</style>
