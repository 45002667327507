<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div v-if="tabelaUtl">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>list</md-icon>
            </div>
            <h4 class="title">Utilizadores</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="utilizadores" table-header-color="green">
              <md-table-row slot="md-table-row" slot-scope="row">
                <md-table-cell md-label="User ID">{{
                  row.item.user_id
                }}</md-table-cell>
                <md-table-cell md-label="Nome">{{
                  row.item.nome
                }}</md-table-cell>
                <md-table-cell md-label="Apelido">{{
                  row.item.apelido
                }}</md-table-cell>
                <md-table-cell md-label="Email">{{
                  row.item.email
                }}</md-table-cell>
                <md-table-cell md-label="Admin">{{
                  row.item.admin
                }}</md-table-cell>
                <md-table-cell md-label="Data ACC Criada">{{
                  row.item.dt_created
                }}</md-table-cell>
                <md-table-cell
                  @click.native="editarUtilizador(row.item)"
                  md-label="Editar"
                  ><md-icon style="margin-left: 10px !important"
                    >edit</md-icon
                  ></md-table-cell
                >
                <md-table-cell
                  @click.native="eliminarUtilizador(row.item.user_id)"
                  md-label="Eliminar"
                  ><md-icon style="margin-left: 20px !important"
                    >delete</md-icon
                  ></md-table-cell
                >
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <div v-if="editarUtl">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>mode_edit_outline</md-icon>
            </div>
            <h4 class="title">Editar Utilizador</h4>
          </md-card-header>

          <md-card-content>
            <md-field>
              <label>Nome:</label>
              <md-input v-model="utilizadoresSelecionado.nome"></md-input>
            </md-field>
            <md-field>
              <label>Apelido:</label>
              <md-input v-model="utilizadoresSelecionado.apelido"></md-input>
            </md-field>

            <md-field>
              <label>Admin:</label>
              <md-select v-model="utilizadoresSelecionado.admin">
                <md-option value="sim">Sim</md-option>
                <md-option value="nao">Nao</md-option>
              </md-select>
            </md-field>
          </md-card-content>

          <md-card-actions>
            <md-button class="md-danger" @click="voltarAtras()"
              >Voltar Atrás</md-button
            >
            <md-button class="md-success" @click="salvarEdicao()"
              >Submit</md-button
            >
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
  import api from "@/api/index";
  export default {
    data() {
      return {
        utilizadores: [],
        utilizadoresSelecionado: [],
        tabelaUtl: true,
        editarUtl: false,
        admin: "",
        nome: "",
        apelido: "",
        email: "",
        erro: false,
      };
    },
    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var utl = await api.listarUtilizadores();
        utl = Object.values(utl);
        this.utilizadores = utl.filter((x) => x.estado == "1");
      },
      editarUtilizador(id) {
        this.utilizadoresSelecionado = id;
        this.tabelaUtl = false;
        this.editarUtl = true;
      },
      async salvarEdicao() {
        if (this.utilizadoresSelecionado.admin == "sim") {
          this.utilizadoresSelecionado.admin = "1";
        } else {
          this.utilizadoresSelecionado.admin = "0";
        }
        let edicaoCompleta = {
          user_id: this.utilizadoresSelecionado.user_id,
          nome: this.utilizadoresSelecionado.nome,
          apelido: this.utilizadoresSelecionado.apelido,
          admin: this.utilizadoresSelecionado.admin,
        };
        var res = await api.updateUtilizadores(edicaoCompleta);
        if (res.resultado == "OK") {
          this.notifyVue(
            "top",
            "right",
            "success",
            "Edicao feita com sucesso !! "
          );
          setTimeout(() => {
            this.editarUtl = false;
            this.tabelaUtl = true;
          }, 2000);
          return;
        } else {
          this.notifyVue(
            "top",
            "right",
            "success",
            "ُErro na edicao de dados !! "
          );
        }
      },
      voltarAtras() {
        this.editarUtl = false;
        this.tabelaUtl = true;
      },
      async eliminarUtilizador(id) {
        var mudarEstado = {
          user_id: id,
        };

        var res = await api.apagarUtilizador(mudarEstado);
        if (res.resultado == "OK") {
          this.notifyVue(
            "top",
            "right",
            "success",
            "Utilizador eliminado com sucesso !! "
          );
          this.inicio();
          return;
        } else {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "ُErro na eliminacao do utilizador !! "
          );
        }
      },
      notifyVue(verticalAlign, horizontalAlign, cor, texto) {
        this.$notify({
          message: texto,
          icon: "add_alert",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: cor,
          timeout: 2500,
        });
      },
    },
  };
</script>
<style></style>
