/* eslint-disable */ 

import axios from "axios";

const instance = axios.create({
    baseURL: 'https://yourcode-staging.com/api-yc-shop/public',
    headers: {
        Authorization: "Basic ZGVjYXRobG9uX2FwaV91c2VyOmU2Sy83NUozKXBZfnNed0w=",
        "content-type": "application/json",
      },
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
});
 
export default {
    // SIGN IN 
    async login(data) {
        try {
            const res = await instance.post('/api/login', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async listarUtilizadores() {
        try {
            const res = await instance.post('/api/utilziadores/listar');
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async adicionarUtilizador(data) {
        try {
            const res = await instance.post('/api/utilizadores/adicionar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },   
    async listarPordutos() {
    
        try {
            const res = await instance.post('/api/produtos/listar');
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async adicionarProduto(data) {
    
        try {
            const res = await instance.post('/api/produtos/adicionar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async adicionarEncomenda(data) {
    
        try {
            const res = await instance.post('/api/encomenda/adicionar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async listarEncomendas(data) {
    
        try {
            const res = await instance.post('/api/encomendas/listar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async updateUtilizadores(data) {
    
        try {
            const res = await instance.post('/api/atualizar/utilizador', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async updateUtilizadoresPass(data) {
    
        try {
            const res = await instance.post('/api/atualizar/utilizador/password', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async apagarUtilizador(data) {
    
        try {
            const res = await instance.post('/api/apagar/utilizador', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async atualizarProduto(data) {
    
        try {
            const res = await instance.post('/api/atualizar/produto', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
    async todasEncomendas(data) {
    
        try {
            const res = await instance.post('/api/encomendas/listar/admin', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }, 
}