<template>
  <div id="temp">
    <div class="md-layout" v-if="produtosExpo">
      <div
        v-for="(prod, i) in produto"
        :key="i"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <product-card class="carrinhoCli" header-animation="true">
          <img class="img" slot="imageHeader" :src="prod.imagem" />

          <h3 slot="title" class="title">
            <a href="#">{{ prod.titulo }}</a>
          </h3>

          <template slot="footer"
            ><p></p>
            <p @click="editarProduto(prod)">Editar <md-icon>edit</md-icon></p>
            <p></p>
          </template>
        </product-card>
      </div>
    </div>
    <div v-if="editarProd" class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>draw</md-icon>
            </div>
            <h4 class="title">Editar o Produto</h4>
          </md-card-header>

          <md-card-content>
            <md-field>
              <label>Categoria:</label>
              <md-select v-model="produtoSelecionado.categoria">
                <md-option value="sapatilha">Sapatilha</md-option>
                <md-option value="casacos">Casaco</md-option>
                <md-option value="calças">Calças</md-option>
                <md-option value="tshirt">T-Shirt</md-option>
                <md-option value="sweatshirt">Sweatshirt</md-option>
              </md-select>
            </md-field>

            <md-field>
              <label>Titulo:</label>
              <md-input v-model="produtoSelecionado.titulo"></md-input>
            </md-field>
            <md-field>
              <label>Descrição:</label>
              <md-input v-model="produtoSelecionado.descricao"></md-input>
            </md-field>
            <md-field>
              <label>Preço:</label>
              <md-input v-model="produtoSelecionado.preco"></md-input>
            </md-field>
            <md-field>
              <label>Stock:</label>
              <md-input v-model="produtoSelecionado.stock"></md-input>
            </md-field>
            <md-field>
              <label>Imagem:</label>
              <md-input v-model="produtoSelecionado.imagem"></md-input>
            </md-field>
          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button class="md-danger" @click="voltarParaTras()"
              >Voltar Atras</md-button
            >
            <md-button class="md-success" @click="salvarProduto()"
              >Guardar</md-button
            >
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { ProductCard } from "@/components";
  import api from "@/api/index";

  export default {
    name: "temp",
    components: {
      ProductCard,
    },

    data() {
      return {
        categoria: "",
        produtoSelecionado: [],
        produto: [],
        produtosExpo: true,
        editarProd: false,
      };
    },
    mounted() {
      this.inicio();
    },
    methods: {
      async inicio() {
        var produtos = await api.listarPordutos();
        produtos = Object.values(produtos);
        this.produto = produtos;
      },
      editarProduto(id) {
        this.produtoSelecionado = id;
        this.produtosExpo = false;
        this.editarProd = true;
      },
      voltarParaTras() {
        this.produtosExpo = true;
        this.editarProd = false;
      },
      async salvarProduto() {
        if (
          this.produtoSelecionado.categoria.trim() == "" ||
          this.produtoSelecionado.titulo.trim() == "" ||
          this.produtoSelecionado.descricao.trim() == "" ||
          this.produtoSelecionado.preco.trim() == "" ||
          this.produtoSelecionado.stock.trim() == "" ||
          this.produtoSelecionado.imagem.trim() == ""
        ) {
          this.notifyVue(
            "top",
            "right",
            "danger",
            "ُPreencha todos os campos !! "
          );
        } else {
          let edicaoNovoProd = {
            id: this.produtoSelecionado.id,
            categoria: this.produtoSelecionado.categoria,
            titulo: this.produtoSelecionado.titulo,
            descricao: this.produtoSelecionado.descricao,
            preco: this.produtoSelecionado.preco,
            stock: this.produtoSelecionado.stock,
            imagem: this.produtoSelecionado.imagem,
          };

          var tempp = await api.atualizarProduto(edicaoNovoProd);
          console.log(tempp);
          if (tempp.resultado == "OK") {
            this.notifyVue(
              "top",
              "right",
              "success",
              "Produto atualizado com sucesso !! "
            );
            this.inicio();
            return;
          } else {
            this.notifyVue(
              "top",
              "right",
              "danger",
              "ُErro no update do produto !! "
            );
          }
        }
      },
      notifyVue(verticalAlign, horizontalAlign, cor, texto) {
        this.$notify({
          message: texto,
          icon: "add_alert",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: cor,
          timeout: 2500,
        });
      },
    },
  };
</script>
